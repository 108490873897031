<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="12" xs="12" md="3" lg="3" xl="3">
        <v-select
          solo
          v-model="lprZoneSelected"
          :items="lprZones"
          :item-text="getZoneText"
          label="Select Zone"
          item-value="zid"
          hide-details
          single-line
          @change="onLprZoneChange"
        ></v-select>
      </v-col>
    </v-row>

    <v-row no-gutters class="pa-2">
      <v-col cols="12">
        <v-card class="pa-0" flat tile>
          <v-container fluid pa-2>
            <v-row no-gutters>
              <v-col cols="3" class="align-self-center pl-2">
                <span class="text-h5">Vehicle Details</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="align-self-center pr-2">
                <v-menu
                  v-model="filterMenu"
                  :close-on-content-click="false"
                  offset-x
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="white"
                      class="elevation-0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark class="black--text"> filter_alt</v-icon>
                    </v-btn>
                  </template>
                  <v-card flat tile max-width="300">
                    <!-- <v-card-title class="py-0">Filter</v-card-title> -->
                    <v-card-text class="">
                      <v-container fluid pa-0>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-text-field
                              v-model="filterObj.plate"
                              label="Plate"
                              outlined
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-text-field
                              v-model="filterObj.make"
                              label="Make"
                              hide-details
                              outlined
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-text-field
                              v-model="filterObj.model"
                              label="Model"
                              outlined
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-text-field
                              v-model="filterObj.color"
                              label="Color"
                              outlined
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <!-- <v-row no-gutters>
                          <v-col cols="12">
                            <v-select outlined label="Validation Status" hide-details v-model="filterObj.validationStatus" :items="validationStatusOptions"></v-select>
                          </v-col>
                        </v-row> -->
                        <!-- <v-row no-gutters>
                          <v-col cols="12">
                            <divrt-date-range
                              v-model="dateRange"
                              display-format="yyyy-MM-dd"
                              no-title
                              :input-props="dateRangeInputProps"
                              :menu-props="dateRangeMenuProps"
                              :presets="dateRangePresets"
                              :action-labels="{ apply: 'Apply' }"
                            >
                            </divrt-date-range>
                          </v-col>
                        </v-row> -->
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-btn
                              block
                              class="elevation-0 primary"
                              @click="advanceFilterLPRData()"
                              >Filter</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-btn
                              block
                              class="elevation-0 primary"
                              @click="clearFilters()"
                              >Clear Filters</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="2" class="px-1">
                <divrt-date-range
                  v-model="dateRange"
                  display-format="MM-dd-yyyy"
                  no-title
                  :input-props="dateRangeInputProps"
                  :menu-props="dateRangeMenuProps"
                  :presets="dateRangePresets"
                  :action-labels="{ apply: 'Apply' }"
                >
                </divrt-date-range>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  hide-details
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="auto" class="align-self-center pl-2">
                <v-btn-toggle v-model="toggle_view" mandatory color="primary">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" value="table">
                        <v-icon>table_view</v-icon>
                      </v-btn>
                    </template>
                    <span>Table View</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" value="grid">
                        <v-icon>grid_view</v-icon>
                      </v-btn>
                    </template>
                    <span>Grid View</span>
                  </v-tooltip>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <lpr-overview
          :plates="lprData"
          :loading="loading"
          :lprTab="vehicleDetailstab"
        ></lpr-overview>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12" class="pa-2">
        <v-card flat>
          <v-tabs
            v-model="vehicleDetailstab"
            background-color="transparent"
            color="black"
            grow
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <!-- {{innerWidth}} -->
            <v-tab class="deep-purple lighten-4">All</v-tab>
            <v-tab class="green lighten-4">Pending Review</v-tab>
            <v-tab class="deep-orange lighten-4">Sent For Violation</v-tab>
            <v-tab class="cyan lighten-4">Exceptions</v-tab>
          </v-tabs>

          <v-tabs-items v-model="vehicleDetailstab">
            <v-tab-item>
              <lpr-list-view
                lprTab="all"
                :user="user"
                :filter="toggle_flag_records"
                :search="search"
                :view="toggle_view"
                :lprData="lprData"
                :loading="loading"
                :zoneFee="zoneFee"
              ></lpr-list-view>
            </v-tab-item>
            <v-tab-item>
              <lpr-list-view
                lprTab="pending"
                :user="user"
                :filter="toggle_flag_records"
                :search="search"
                :view="toggle_view"
                :lprData="pedingReviewedLprData"
                :loading="loading"
                :zoneFee="zoneFee"
              ></lpr-list-view>
            </v-tab-item>
            <v-tab-item>
              <lpr-list-view
                lprTab="issued"
                :user="user"
                :filter="toggle_flag_records"
                :search="search"
                :view="toggle_view"
                :lprData="tickedIssuedLprData"
                :loading="loading"
                :zoneFee="zoneFee"
              ></lpr-list-view>
            </v-tab-item>
            <v-tab-item>
              <lpr-list-view
                lprTab="exception"
                :user="user"
                :filter="toggle_flag_records"
                :search="search"
                :view="toggle_view"
                :lprData="exceptionLprData"
                :loading="loading"
                :zoneFee="zoneFee"
              ></lpr-list-view>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row no-gutters>
      <v-col cols="12">
        <lpr-list-view
          :filter="toggle_flag_records"
          :search="search"
          :view="toggle_view"
          :lprData="lprData"
          :loading="loading"
          :zoneFee="zoneFee"
        ></lpr-list-view>
      </v-col>
    </v-row> -->
    <!-- <v-row no-gutters v-show="toggle_view =='table'">
      <v-col cols="12">
        <lpr-table :search="search"></lpr-table>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import * as lprAPI from "@/api/lprAPI";
import LprListView from "@/components/lpr/LprListView.vue";
import { EventBus } from "@/lib/EventBus";
import LprOverview from "@/components/lpr/LprOverview.vue";
import DivrtDateRange from "@/uiComponents/DateRange.vue";
import { presets } from "@/utils/constants";
import { mapGetters } from "vuex";
// import LprTable from "@/components/lpr/LprTable.vue";
export default {
  name: "LPRView",
  components: { LprListView, LprOverview, DivrtDateRange },
  data() {
    return {
      innerWidth: window.innerWidth / 4,
      // validationStatusOptions:["Override: Violation", "Override: Exception"],tab: null,
      vehicleDetailstab: null,
      dateRange: {}, //{ "start": "2021-02-18", "end": "2021-02-27" }
      dateRangeInputProps: { outlined: true },
      dateRangeMenuProps: {
        transition: "scale-transition",
        offsetX: false,
        closeOnContentClick: false,
        bottom: true,
      },
      dateRangePresets: presets,
      filterObj: {
        plate: "",
        make: "",
        model: "",
        color: "",
        // validationStatus:""
      },
      toggle_flag_records: "all",
      filterMenu: false,
      lprZoneSelected: { name: "Commerce B", zcode: 62276 },
      lprZones: [{ name: "Commerce B", zcode: 62276 }],
      toggle_view: "table",
      search: "",
      lprData: [],
      pedingReviewedLprData: [],
      exceptionLprData: [],
      tickedIssuedLprData: [],
      zoneFee: {
        discountAmount: null,
        discountRedemptionPeriod: null,
        daysBeforeCollections: 45,
        ticketTitle: "Parking Violation Notice",
        ticketHeader: null,
        ticketFooter:
          "615-695-9256\nEmail: nashville@spplus.com\nSee back for payment instructions",
        violations: true,
        transient: true,
        digital: false,
        parkMobile: false,
        monthlyParker: true,
        timeZoneName: "Central Standard Time",
        timeZoneCode: "CST",
        LOCATION_KEY: 8665,
        parkingFees: [
          {
            feeDetailKey: 142190,
            feeType: "Handheld Fees",
            publish: true,
            rate: "15.00",
            rateDescription: "Daily Max",
          },
          {
            feeDetailKey: 141928,
            feeType: "Handheld Fees",
            publish: true,
            rate: "20.00",
            rateDescription: "Special Event $20",
          },
        ],
        violationFees: [
          {
            feeDetailKey: 143020,
            feeType: "Violation Rates",
            publish: true,
            rate: "0.00",
            rateDescription: "Non payment",
          },
          {
            feeDetailKey: 143034,
            feeType: "Violation Rates",
            publish: true,
            rate: "0.00",
            rateDescription: "5 Minute Zone",
          },
          {
            feeDetailKey: 143023,
            feeType: "Violation Rates",
            publish: true,
            rate: "0.00",
            rateDescription: "Invalid Monthly Tag",
          },
          {
            feeDetailKey: 143035,
            feeType: "Violation Rates",
            publish: true,
            rate: "0.00",
            rateDescription: "Handicap",
          },
          {
            feeDetailKey: 143022,
            feeType: "Violation Rates",
            publish: true,
            rate: "0.0000",
            rateDescription: "Expired Receipt",
          },
          {
            feeDetailKey: 143024,
            feeType: "Violation Rates",
            publish: true,
            rate: "10.00",
            rateDescription: "Machine Down/ After Hours ",
          },
          {
            feeDetailKey: 143021,
            feeType: "Violation Rates",
            publish: true,
            rate: "0.00",
            rateDescription: "Improperly Parked",
          },
          {
            feeDetailKey: 143033,
            feeType: "Violation Rates",
            publish: true,
            rate: "0.00",
            rateDescription: "Invalid Plates",
          },
        ],
        adminFees: [
          {
            feeDetailKey: 547798,
            feeType: "Admin Fees",
            publish: true,
            rate: "30.00",
            rateDescription: "Admin Fee Expired Receipt",
          },
          {
            feeDetailKey: 143843,
            feeType: "Admin Fees",
            publish: true,
            rate: "50.00",
            rateDescription: "Admin Fee No Receipt",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "pinaStore/getterUser",
    }),
    loading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"]("LPR_VIEW");
    },
  },
  watch: {
    dateRange() {
      this.advanceFilterLPRData();
    },
  },
  mounted() {
    // this.getProviderZones();
    var self = this;
    this.getLPRDataByZone();
    EventBus.$on("user-plate-edited", () => {
      self.getLPRDataByZone();
    });
    EventBus.$on("ticket-issued", () => {
      self.getLPRDataByZone();
    });
    EventBus.$on("card-clicked", (tab) => {
      this.switchTab(tab);
    });
  },
  methods: {
    switchTab(tab) {
      this.vehicleDetailstab = tab;
    },
    getZoneText(item) {
      return item.zcode + " ( " + item.name + " )";
    },
    exportAsCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(this.lprData[0]).join(";"),
        ...this.lprData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    async getLPRDataByZone() {
      this.$store.commit("pinaStore/SET_LOADING_STATUS", {
        property: "LPR_VIEW",
        pending: true,
      });
      let zoneLPRData = await lprAPI.getLPRDataByZone({ zid: 62276 });
      this.$store.commit("pinaStore/SET_LOADING_STATUS", {
        property: "LPR_VIEW",
        pending: false,
      });
      this.lprData = zoneLPRData?.data?.data ? zoneLPRData.data.data : [];
      this.pedingReviewedLprData = zoneLPRData?.data?.data
        ? zoneLPRData.data.data.filter(
            (record) =>
              record.validationStatus == "Invalid User" ||
              record.validationStatus == "Valid User"
          )
        : [];
      this.exceptionLprData = zoneLPRData?.data?.data
        ? zoneLPRData.data.data.filter(
            (record) => record.validationStatus == "Override: Exception"
          )
        : [];
      this.tickedIssuedLprData = zoneLPRData?.data?.data
        ? zoneLPRData.data.data.filter((record) => record.issuedTicket)
        : [];
    },
    async advanceFilterLPRData() {
      this.filterMenu = false;
      let end =
        this.dateRange.end == "" || this.dateRange.end == undefined
          ? []
          : this.dateRange.end.split("-");
      // let filterOptions = Object.assign(this.filterObj);
      let startDate =
        this.dateRange.start == "" || this.dateRange.start == undefined
          ? ""
          : new Date(this.dateRange.start).toUTCString();
      let endDate =
        this.dateRange.end == "" || this.dateRange.end == undefined
          ? ""
          : new Date(
              Date.UTC(end[0], Number(end[1]) - 1, end[2], 23, 59, 59)
            ).toUTCString();

      let postDataObj = Object.assign(
        {},
        { startDate: startDate, endDate: endDate },
        this.filterObj
      );
      let zoneLPRData = await lprAPI.getFilteredLPRData("62276", postDataObj);
      this.lprData = zoneLPRData?.data?.data ? zoneLPRData.data.data : [];
      this.pedingReviewedLprData = zoneLPRData?.data?.data
        ? zoneLPRData.data.data.filter(
            (record) =>
              record.validationStatus == "Invalid User" ||
              record.validationStatus == "Valid User"
          )
        : [];
      this.exceptionLprData = zoneLPRData?.data?.data
        ? zoneLPRData.data.data.filter(
            (record) => record.validationStatus == "Override: Exception"
          )
        : [];
      this.tickedIssuedLprData = zoneLPRData?.data?.data
        ? zoneLPRData.data.data.filter((record) => record.issuedTicket)
        : [];
    },
    clearFilters() {
      this.filterObj = Object.assign(
        {},
        {
          plate: "",
          make: "",
          model: "",
          color: "",
          // validationStatus:""
        }
      );
      this.dateRange = Object.assign({}, {});
    },
    // async getProviderZones() {
    //   let providerZonesData = await lprAPI.getProviderZones();
    //   this.lprZones = providerZonesData?.data?.zones
    //     ? providerZonesData.data.zones
    //     : [];
    //   this.lprZoneSelected = this.lprZones[0];
    //   console.log(providerZonesData);
    // },
    onLprZoneChange() {},
  },
};
</script>
<style lang="sass">
.vehicle-tab-active
  background: red ! important
</style>

