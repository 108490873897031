<template>
  <v-container fluid pa-0>
    <v-row no-gutters class="justify-space-around">
      <v-col cols="12" sm="12" xs="12" md="2" lg="2" xl="2" class="pr-1">
        <v-card
          flat
          class="text-center justify-center pa-2 deep-purple lighten-4 black--text"
          :class="cardActive(0)"
          @click="onClickCard('total')"
        >
          <v-row no-gutters>
            <v-col cols="12" class="align-self-center">
              <span class="text-h2">{{ plates.length }}</span>
            </v-col>
            <v-col class="align-self-center text-center pt-2 text-overline">
              ALL
            </v-col>
          </v-row>
          <loading-overlay :loading="loading"></loading-overlay>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="2" lg="2" xl="2" class="pr-1">
        <v-card
          flat
          class="text-center justify-center pa-2 green lighten-4 black--text"
          :class="cardActive(1)"
          @click="onClickCard('pending')"
        >
          <v-row no-gutters>
            <v-col cols="12" class="align-self-center">
              <span class="text-h2">{{
                this.plates.filter(
                  (record) =>
                    record.validationStatus == "Invalid User" ||
                    record.validationStatus == "Valid User"
                ).length
              }}</span>
            </v-col>
            <v-col class="align-self-center text-center pt-2 text-overline">
              PENDING REVIEW
            </v-col>
          </v-row>
          <loading-overlay :loading="loading"></loading-overlay>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="2" lg="2" xl="2" class="pr-1">
        <v-card
          flat
          class="text-center justify-center pa-2 deep-orange lighten-4 black--text"
          :class="cardActive(2)"
          @click="onClickCard('violation')"
        >
          <v-row no-gutters>
            <v-col cols="12" class="align-self-center">
              <span class="text-h2">{{
                this.plates.filter((record) => record.issuedTicket == true)
                  .length
              }}</span>
            </v-col>
            <v-col class="align-self-center text-center pt-2 text-overline">
              SENT FOR VIOLATION
            </v-col>
          </v-row>
          <loading-overlay :loading="loading"></loading-overlay>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="2" lg="2" xl="2" class="pr-1">
        <v-card
          flat
          class="text-center justify-center pa-2 cyan lighten-4 black--text"
          :class="cardActive(3)"
          @click="onClickCard('exception')"
        >
          <v-row no-gutters>
            <v-col cols="12" class="align-self-center">
              <span class="text-h2">{{
                this.plates.filter(
                  (record) => record.validationStatus == "Override: Exception"
                ).length
              }}</span>
            </v-col>
            <v-col class="align-self-center text-center pt-2 text-overline">
              EXCEPTIONS
            </v-col>
          </v-row>
          <loading-overlay :loading="loading"></loading-overlay>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" sm="12" xs="12" md="2" lg="2" xl="2" class="pr-1">
        <v-card
          flat
          class="text-center justify-center pa-2 yellow lighten-4 black--text"
        >
          <v-row no-gutters>
            <v-col cols="12" class="align-self-center">
              <span class="text-h2">{{
                this.plates.filter((record) => record.manuallyUpdated == true)
                  .length
              }}</span>
            </v-col>
            <v-col class="align-self-center text-center pt-2 text-overline">
              MANUALLY UPDATED PLATES
            </v-col>
          </v-row>
          <loading-overlay :loading="loading"></loading-overlay>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
export default {
  name: "LprOverview",
  components: {},
  props: {
    plates: {
      type: Array,
      default: function () {
        return [];
      },
    },
    lprTab: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      inValidPlates: "",
      flaggedPlates: "",
      validPlates: "",
    };
  },
  computed: {
  },
  watch: {
    // plates() {
    //   this.getInValidPlates();
    //   this.getFlaggedPlates();
    //   this.getValidPlates();
    // },
  },
  methods: {
    cardActive(card){
      return (this.lprTab == card) ? 'elevation-4' : ""
    },
    onClickCard(cardType) {
      switch (cardType) {
        case "total":
          EventBus.$emit("card-clicked",0);
          break;
        case "pending":
          EventBus.$emit("card-clicked",1);
          break;
        case "violation":
          EventBus.$emit("card-clicked",2);
          break;
        case "exception":
          EventBus.$emit("card-clicked",3);
          break;
      }
    },
    //   getInValidPlates() {
    //     this.inValidPlates =
    //       this.plates.length > 0
    //         ? this.plates.filter(
    //             (record) =>
    //               record.validationStatus == "Invalid User" ||
    //               record.validationStatus == "Override: Violation" ||
    //               record.validationStatus == "Override: Exception"
    //           ).length
    //         : "";
    //   },
    //   getFlaggedPlates() {
    //     this.flaggedPlates =
    //       this.plates.length > 0
    //         ? this.plates.filter((record) => record.issuedTicket == true).length
    //         : "";
    //   },
    //   getValidPlates() {
    //     this.validPlates =
    //       this.plates.length > 0
    //         ? this.plates.filter(
    //             (record) => record.validationStatus == "Valid User"
    //           ).length
    //         : "";
    // },
  },
};
</script>
