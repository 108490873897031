import axios from 'axios';
// import config from  '../../../config/config'
// import axiosHelper from './apiHelper'
const config = {
  apiURL: "https://enforcement-api-dev.divrt.co/",
  loggerURL: "https://loggerapi-dev.divrt.co/log",
  divrt: "c43c372fee21067a9326619e139c9f2498e523b81b3406e5af008294b7809972",
  lprToken: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7Il9ic29udHlwZSI6Ik9iamVjdElEIiwiaWQiOnsidHlwZSI6IkJ1ZmZlciIsImRhdGEiOls5NCwyNDAsMTYyLDE2MiwyMzQsMjEsMTY0LDE4NCwxOTUsMjUzLDExMiwyMzJdfX0sImlhdCI6MTU5MjkwNzczNiwiZXhwIjoxNjI4OTA3NzM2fQ.fla2T17WvRKaUf5fkdUSU6YXSUtzJ8PE8JaZgG42Y8o"
}

const API = axios.create({
  baseURL: config.apiURL, //'https://meghak2.divrt.co/api/v1/',
  // baseURL: 'http://localhost:3000',
  withCredentials: true,
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization':config.lprToken
  }
});
API.interceptors.request.use(
  request => {
    /** TODO: Add any request interceptors */
    return request;
  },
  error => {
    /** TODO: Do something with response error */
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  response => {
    /** TODO: Add any response interceptors */
    return response;
  },
  error => {
    /** TODO: Do something with response error */
    return Promise.reject(error);
  }
);

// import axiosHelper from 'axiosHelper'
const processRequest = async (reqType, url, data) => {
  switch (reqType) {
    case 'GET':
      try {
        var apiGetResponse = await apiGetRequest(url, data);
        return apiGetResponse;
      } catch (error) {
        console.log(error);
      }
      break;
    case 'POST':
      try {
        var apiPostResponse = await apiPostRequest(url, data);
        return apiPostResponse;
      } catch (error) {
        console.log(error);
      }
      break;
    case 'PUT':
      try {
        var apiPutResponse = await apiPutRequest(url, data);
        return apiPutResponse;
      } catch (error) {
        console.log(error);
      }
      break;
    case 'DELETE':
      try {
        var apiDeleteResponse = await apiDeleteRequest(url, data);
        return apiDeleteResponse;
      } catch (error) {
        console.log(error);
      }
      break;
  }
};
const apiGetRequest = async (url, data) => {
  try {
    return API.get(url, data);
  } catch (error) {
    return error
  }
};

const apiPostRequest = async (url, data) => {
  try {
    return API.post(url, data)
  } catch (error) {
    return error
  }
};

const apiDeleteRequest = async (url, data) => {
  try {
    return API.delete(url, data)
  } catch (error) {
    return error
  }
};

const apiPutRequest = async (url, data) => {
  try {
    return API.put(url, data)
  } catch (error) {
    return error
  }
};

export default processRequest;