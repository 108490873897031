import { format, subDays, getDaysInMonth } from 'date-fns';
export const ISO_FORMAT = 'yyyy-MM-dd';
export const CHICAGO_DATE_FORMAT = 'MM-dd-yyyy';
export const US_DATE_FORMAT = 'MM-dd-yy';
export const US_DATE_TIME_FORMAT = 'MM-dd-yy HH:mm:ss';
export const DEFAULT_DATE = format(new Date(), ISO_FORMAT);
export const DEFAULT_ACTION_LABELS = {
    apply: 'Apply',
    cancel: 'Cancel',
    reset: 'Reset'
};
export const presets =  [
    {
      label: 'Today',
      range: [
        format(new Date(), ISO_FORMAT),
        format(new Date(), ISO_FORMAT)
      ]
    },
    {
      label: 'Yesterday',
      range: [
        format(subDays(new Date(), 1), ISO_FORMAT),
        format(subDays(new Date(), 1), ISO_FORMAT)
      ]
    },
     {
      label: 'Last 7 Days',
      range: [
        format(subDays(new Date(), 6), ISO_FORMAT),
        format(subDays(new Date(), 0), ISO_FORMAT)
      ]
    },
     {
      label: 'Last 30 Days',
      range: [
        format(subDays(new Date(), 29), ISO_FORMAT),
        format(subDays(new Date(), 0), ISO_FORMAT)
      ]
    },
    {
      label: 'This Month',
      range: [
        format(subDays(new Date(new Date().getFullYear(),new Date().getMonth(), 1), 0), ISO_FORMAT),
        format(subDays(new Date(), 0), ISO_FORMAT)
      ]
    },
     {
      label: 'Last Month',
      range: [
        format(subDays(new Date(new Date().getFullYear() , new Date().getMonth(), 1), getDaysInMonth(new Date(new Date().getFullYear(), new Date().getMonth()-1))), ISO_FORMAT),
        format(subDays(new Date(new Date().getFullYear(),new Date().getMonth(), 1), 1), ISO_FORMAT)
      ]
    },
  ]