import lprAPIHelper from './lprAPIHelper';
// import config from '../../../config/config';
// const config = {
//   apiURL: "https://meghak2.divrt.co",
//   loggerURL: "https://loggerapi-dev.divrt.co/log",
//   divrt: "c43c372fee21067a9326619e139c9f2498e523b81b3406e5af008294b7809972"
// }

const getLPRDataByZone = async (data) => {
  // Object.assign(data, { divrt: config.divrt });
  return await lprAPIHelper("GET", "/api/v1/enforcement/validateLprV2/"+data.zid)
}
const updateLPRData = async (plateId, data) => {
  // Object.assign(data, { divrt: config.divrt });
  return await lprAPIHelper("PUT", "/api/v1/enforcement/validateLprV2/"+plateId, data)
}
const getFilteredLPRData = async (zid, data) => {
  // Object.assign(data, { divrt: config.divrt });
  return await lprAPIHelper("POST", "/api/v1/enforcement/validateLprV2/advfilter/"+zid, data)
}
const issueTicket = async (zid, plateId, data) => {
  // Object.assign(data, { divrt: config.divrt });
  return await lprAPIHelper("POST", "/api/v1/enforcement/validateLprV2/issueTicketToVoilator/"+zid+"/"+plateId, data)
}
const login = async (data) => {
  // Object.assign(data, { divrt: config.divrt });
  return await lprAPIHelper("POST", "/api/v1/users/login", data)
}

// 60705/?perPage=10&page=1
export {
  getLPRDataByZone, updateLPRData, getFilteredLPRData, issueTicket, login
}