<template>
  <v-card flat class="pt-1" style="background-color: #f4f6f9">
    <v-card-text class="pa-0">
      <v-data-table
        v-show="view == 'table'"
        :headers="filterLprHeaders"
        :items="lprData"
        :search="search"
        :items-per-page="15"
        :item-class="itemRowBackground"
        item-key="_id"
        class="pa-2"
      >
        <template v-slot:[`item.plate_image`]="{ item }">
          <div :style="getImagePlate(item)"></div>
        </template>

        <template v-slot:[`item.thumbnail`]="{ item }">
          <div style="position: relative">
            <v-img
              :src="
                'https://divrt-lpr-thumb.s3.amazonaws.com/' + item.imageName
              "
              cover
              height="120"
              width="100%"
              class="cursor-pointer pt-3"
              @click="openVehicleImgDialog(item)"
            ></v-img>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  color="white"
                  size="20"
                  class="image-count"
                  >{{ item.duplicateImages.length }}</v-avatar
                >
              </template>
              <span> Image Count</span>
            </v-tooltip>
            <v-tooltip top v-if="item.manuallyUpdated" color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  color="white"
                  size="20"
                  class="manual-update"
                  ><v-icon x-small color="red lighten-1">
                    mode
                  </v-icon></v-avatar
                >
              </template>
              <span
                class="white--text"
                v-if="Object.hasOwnProperty.call(item, 'editedBy')"
              >
                Manually updated by
                <b>{{ item.editedBy.name }} / {{ item.editedBy.email }}</b> on
                <b>{{ formatISODate(item.editedBy.date) }}</b></span
              >
              <span v-else>Manually Updated</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.plate`]="{ item }" style="position: relative">
          <v-edit-dialog
            :return-value.sync="item.plate"
            v-if="isEditable(item)"
            large
            persistent
            @save="saveFieldEditDetails(item)"
          >
            <span class="text-h5">{{ item.plate }}</span>

            <template v-slot:input>
              <div class="mt-4 title">Update Plate</div>
              <v-text-field
                v-model="item.plate"
                label="Edit"
                single-line
                counter
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <span v-else class="text-h5">{{ item.plate }}</span>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  color="yellow"
                  size="20"
                  class="plate-warning-icon elevation-0"
                  v-show="item.conf < 85"
                >
                  <v-icon x-small color="black"> priority_high </v-icon>
                </v-avatar>
              </template>
              <span>conf below 85</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  size="20"
                  class="plate-edit-check-icon elevation-0"
                  v-show="item.plate != item.plateOld"
                >
                  <v-icon x-small color="black"> check </v-icon>
                </v-avatar>
              </template>
              <span> Manually edited</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.make`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.make"
            v-if="isEditable(item)"
            large
            persistent
            @save="saveFieldEditDetails(item)"
          >
            <span>{{ item.make }}</span>

            <template v-slot:input>
              <div class="mt-4 title">Update Make</div>
              <v-text-field
                v-model="item.make"
                label="Edit"
                single-line
                counter
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <span v-else>{{ item.make }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                v-on="on"
                color="green"
                size="20"
                class="edit-check-icon elevation-0"
                v-show="item.make != item.makeOld"
              >
                <v-icon x-small color="black"> check </v-icon>
              </v-avatar>
            </template>
            <span> Manually edited</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.model`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.model"
            v-if="isEditable(item)"
            large
            persistent
            @save="saveFieldEditDetails(item)"
          >
            <span>{{ item.model }}</span>

            <template v-slot:input>
              <div class="mt-4 title">Update Model</div>
              <v-text-field
                v-model="item.model"
                label="Edit"
                single-line
                counter
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <span v-else>{{ item.model }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                v-on="on"
                color="green"
                size="20"
                class="edit-check-icon elevation-0"
                v-show="item.model != item.modelOld"
              >
                <v-icon x-small color="black"> check </v-icon>
              </v-avatar>
            </template>
            <span> Manually edited</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.color`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.color"
            v-if="isEditable(item)"
            large
            persistent
            @save="saveFieldEditDetails(item)"
          >
            <span>{{ item.color }}</span>
            <template v-slot:input>
              <div class="mt-4 title">Update Color</div>
              <v-text-field
                v-model="item.color"
                label="Edit"
                single-line
                counter
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <span v-else>{{ item.color }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                v-on="on"
                color="green"
                size="20"
                class="edit-check-icon elevation-0"
                v-show="item.color != item.colorOld"
              >
                <v-icon x-small color="black"> check </v-icon>
              </v-avatar>
            </template>
            <span> Manually edited</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.camTimeEpoch`]="{ item }">
          {{ formatDate(item) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            bottom
            v-if="
              ((item.validationStatus == 'Invalid User' ||
                item.validationStatus == 'Valid User') &&
                lprTab == 'all') ||
              lprTab == 'pending'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="white"
                class="orange--text elevation-0 ma-1"
                small
                @click="openEditVehicleDialog(item)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span> Edit</span>
          </v-tooltip>
          <v-tooltip
            bottom
            color="black"
            v-if="
              item.validationStatus == 'Override: Exception' ||
              item.validationStatus == 'Override: Violation'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="white"
                class="green--text elevation-0 ma-1"
                small
              >
                <v-icon>info</v-icon>
              </v-btn>
            </template>
            <span
              class="white--text"
              v-if="Object.hasOwnProperty.call(item, 'reviewedBy')"
            >
              {{
                item.validationStatus == "Override: Exception"
                  ? "Exception added by"
                  : "Violation issued by"
              }}
              <b>{{ item.reviewedBy.name }} / {{ item.reviewedBy.email }}</b>
              on
              <b>{{ formatISODate(item.reviewedBy.date) }}</b> <br /><b
                v-if="Object.hasOwnProperty.call(item, 'notesForException')"
                >Notes : </b
              >{{
                Object.hasOwnProperty.call(item, "notesForException")
                  ? item.notesForException
                  : ""
              }}
            </span>
            <span v-else>Reviewed</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              !item.issuedTicket &&
              (item.validationStatus == 'Invalid User' ||
                item.validationStatus == 'Valid User') &&
              (lprTab == 'all' || lprTab == 'pending')
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="white"
                class="red--text elevation-0"
                small
                @click="openConfirmReportDialog(item)"
              >
                <v-icon>confirmation_number</v-icon>
              </v-btn>
            </template>
            <span> Report</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              item.issuedTicket &&
              item.validationStatus != 'Valid User' &&
              lprTab == 'all'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="grey lighten-4"
                class="red--text elevation-0"
                small
              >
                <v-icon>flag</v-icon>
              </v-btn>
            </template>
            <span>Violation issued</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-data-table
        v-show="view == 'grid'"
        :headers="filterLprHeaders"
        :items="lprData"
        :search="search"
        item-key="_id"
        disable-pagination
        hide-default-footer
        hide-default-header
        style="background-color: #f4f6f9"
        class="pa-2"
      >
        <template v-slot:body="{ items }">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col
                cols="3"
                class="pa-1"
                v-for="(item, i) in items"
                :key="'img-' + i"
              >
                <v-card tile flat width="400">
                  <div style="position: relative">
                    <v-img
                      style="position: relative"
                      :src="item.thumbnail"
                      cover
                      max-height="200"
                      class="cursor-pointer"
                      @click="openVehicleImgDialog(item)"
                    >
                    </v-img>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="20"
                          class="image-count"
                          >{{ item.duplicateImages.length }}</v-avatar
                        >
                      </template>
                      <span> Image Count</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.manuallyUpdated" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="20"
                          class="manual-update"
                          ><v-icon x-small color="red lighten-1">
                            mode
                          </v-icon></v-avatar
                        >
                      </template>
                      <span
                        class="white--text"
                        v-if="Object.hasOwnProperty.call(item, 'editedBy')"
                      >
                        Manually updated by
                        <b
                          >{{ item.editedBy.name }} /
                          {{ item.editedBy.email }}</b
                        >
                        on
                        <b>{{ formatISODate(item.editedBy.date) }}</b></span
                      >
                      <span v-else>Manually updated</span>
                    </v-tooltip>
                  </div>
                  <!-- <div :style="getImagePlate(item) + ';width:100%'"></div> -->
                  <!-- <v-card-title>{{ item.img_plateText }}</v-card-title> -->
                  <v-hover v-slot="{ hover }">
                    <v-card-text style="position: relative">
                      <v-tooltip
                        top
                        v-if="
                          !item.issuedTicket &&
                          item.validationStatus != 'Valid User'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            absolute
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            class="red--text"
                            fab
                            small
                            right
                            top
                            @click="openConfirmReportDialog(item)"
                          >
                            <v-icon>confirmation_number</v-icon>
                          </v-btn>
                        </template>
                        <span> Report</span>
                      </v-tooltip>
                      <v-tooltip
                        top
                        v-if="
                          item.issuedTicket &&
                          item.validationStatus != 'Valid User'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            absolute
                            v-bind="attrs"
                            v-on="on"
                            color="grey lighten-4"
                            class="red--text"
                            fab
                            small
                            right
                            top
                          >
                            <v-icon>flag</v-icon>
                          </v-btn>
                        </template>
                        <span>violation issued</span>
                      </v-tooltip>
                      <v-container
                        fluid
                        pa-0
                        class="black--text text-uppercase"
                      >
                        <v-row no-gutters>
                          <v-col cols="4" class="text-right pr-2 text-caption">
                            Plate
                          </v-col>
                          <v-col
                            cols="8"
                            class="text-left pl-2 align-self-center card-info"
                          >
                            <span style="font-weight: bold">{{
                              item.plate
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters>
                          <v-col cols="4" class="text-right pr-2 text-caption">
                            Date
                          </v-col>
                          <v-col
                            cols="8"
                            class="text-left pl-2 align-self-center card-info"
                          >
                            {{ formatDate(item) }}
                          </v-col>
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters>
                          <v-col cols="4" class="text-right pr-2 text-caption">
                            Make
                          </v-col>
                          <v-col
                            cols="8"
                            class="text-left pl-2 align-self-center card-info"
                          >
                            {{ item.make }}
                          </v-col>
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters>
                          <v-col cols="4" class="text-right pr-2 text-caption">
                            Model
                          </v-col>
                          <v-col
                            cols="8"
                            class="text-left pl-2 align-self-center card-info"
                          >
                            {{ item.model }}
                          </v-col>
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters>
                          <v-col cols="4" class="text-right pr-2 text-caption">
                            Color
                          </v-col>
                          <v-col
                            cols="8"
                            class="text-left pl-2 align-self-center card-info"
                          >
                            {{ item.color }}
                          </v-col>
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters>
                          <v-col cols="4" class="text-right pr-2 text-caption">
                            Lane
                          </v-col>
                          <v-col
                            cols="8"
                            class="text-left pl-2 align-self-center card-info"
                          >
                            {{ item.laneType }}
                          </v-col>
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters>
                          <v-col cols="4" class="text-right pr-2 text-caption">
                            State
                          </v-col>
                          <v-col
                            cols="8"
                            class="text-left pl-2 align-self-center card-info"
                          >
                            {{ item.state }}
                          </v-col>
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters :class="itemBackground(item)">
                          <v-col cols="4" class="text-right pr-2 text-caption">
                            Validation Status
                          </v-col>
                          <v-col
                            cols="8"
                            class="text-left pl-2 align-self-center card-info"
                          >
                            {{ item.validationStatus }}
                          </v-col>
                        </v-row>
                        <v-expand-transition>
                          <v-btn
                            v-if="hover && !item.issuedTicket"
                            class="transition-fast-in-fast-out white v-card--reveal orange--text"
                            @click="openEditVehicleDialog(item)"
                          >
                            Edit</v-btn
                          >
                        </v-expand-transition>
                      </v-container>
                    </v-card-text>
                  </v-hover>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-data-table>
      <loading-overlay :loading="loading"></loading-overlay>
    </v-card-text>
    <v-dialog v-model="confirmReportDialog" persistent max-width="790">
      <v-card
        style="background-color: #f4f6f9; position: relative"
        min-height="520"
      >
        <v-card-title class="headline"> Report Vehicle </v-card-title>
        <v-card-text>
          <v-container fluid pa-0 class="black--text">
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn-toggle
                  v-model="reviewValidation"
                  tile
                  color="primary"
                  class="text--darken-2"
                  group
                >
                  <v-btn value="violation"> Violation </v-btn>
                  <v-btn value="exception"> Exception </v-btn>
                </v-btn-toggle>
                <!-- <v-radio-group v-model="reviewValidation" row>
                  <v-radio value="exception">
                    <template v-slot:label>
                      <span class="black--text">Exception</span>
                    </template>
                  </v-radio>
                  <v-radio value="violation"
                    ><template v-slot:label>
                      <span class="black--text">Violation</span>
                    </template></v-radio
                  >
                </v-radio-group> -->
              </v-col>
            </v-row>
            <v-row no-gutters v-show="reviewValidation == 'exception'">
              <v-col cols="12">
                <v-textarea
                  v-model="exceptionNotes"
                  outlined
                  name="addNote"
                  label="Exception notes"
                  rows="5"
                  counter
                  maxlength="250"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row no-gutters v-show="reviewValidation == 'violation'">
              <v-col cols="4" class="text-right align-self-center pr-3">
                Select Violation Rule
              </v-col>
              <v-col cols="6">
                <v-select
                  solo
                  flat
                  v-model="selectedViolatedRule"
                  :items="violatedRules"
                  label="Select violated rule"
                  hide-details
                  single-line
                  @change="onViolatedRuleChange"
                ></v-select>
              </v-col>
            </v-row>
            <v-container
              fluid
              pa-0
              v-show="
                selectedViolatedRule != '' && reviewValidation == 'violation'
              "
              class="pt-2"
            >
              <v-row no-gutters>
                <v-col cols="4" class="text-right align-self-center pr-3">
                  Parking Fee
                </v-col>
                <v-col cols="6" class="text-left align-self-center">
                  <v-text-field
                    solo
                    flat
                    v-model="parkingFee"
                    readonly
                    hide-details
                    single-line
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4" class="text-right align-self-center pr-3">
                  Violation Fee
                </v-col>
                <v-col cols="6" class="text-left align-self-center">
                  <v-text-field
                    solo
                    flat
                    readonly
                    v-model="violationFee"
                    hide-details
                    single-line
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4" class="text-right align-self-center pr-3">
                  Admin Fee
                </v-col>
                <v-col cols="6" class="text-left align-self-center">
                  <v-text-field
                    solo
                    flat
                    readonly
                    v-model="adminFee"
                    hide-details
                    single-line
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4" class="text-right align-self-center pr-3">
                </v-col>
                <v-col cols="6" class="text-left align-self-center">
                  <v-textarea
                    v-model="violationNotes"
                    outlined
                    name="violationNote"
                    label="Violation notes"
                    rows="2"
                    counter
                    maxlength="250"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-card-text>
        <!-- Send ticket to enforcement? -->
        <v-card-actions class="report-vehicle-actions">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmReportDialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            :loading="reportUserPlateBtnLoading"
            @click="reportUserPlate()"
            v-show="reviewValidation == 'violation'"
            :disabled="selectedViolatedRule == '' || violationNotes == ''"
          >
            Issue Violation
          </v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            :loading="reportUserPlateBtnLoading"
            @click="markForException()"
            v-show="reviewValidation == 'exception'"
            :disabled="exceptionNotes == ''"
          >
            Mark for exception
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editVehicleDialog">
      <v-card style="background-color: #f4f6f9">
        <v-card-title>Edit Vehicle Details</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-2">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col cols="7">
                <v-row
                  no-gutters
                  v-if="Object.keys(selectedVehicle).length > 0"
                >
                  <v-col cols="12">
                    <v-img
                      :src="
                        'https://divrt-lpr.s3.amazonaws.com/' +
                        selectedVehicle.imageName
                      "
                      :lazy-src="
                        'https://divrt-lpr.s3.amazonaws.com/' +
                        selectedVehicle.imageName
                      "
                      contain
                      height="450"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="8"
                    class="text-right pb-2"
                    v-if="Object.keys(selectedVehicle).length > 0"
                  >
                    <div :style="getImagePlate(selectedVehicle)"></div>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="4"
                    class="text-right pt-2pr-2 black--text align-self-center"
                  >
                    Plate
                  </v-col>
                  <v-col cols="8" class="text-left">
                    <v-text-field
                      solo
                      flat
                      hide-details
                      v-model="selectedVehicle.plate"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="4"
                    class="text-right pr-2 black--text align-self-center"
                  >
                    Make
                  </v-col>
                  <v-col cols="8" class="text-left">
                    <v-text-field
                      solo
                      flat
                      hide-details
                      v-model="selectedVehicle.make"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="4"
                    class="text-right pr-2 black--text align-self-center"
                  >
                    Model
                  </v-col>
                  <v-col cols="8" class="text-left">
                    <v-text-field
                      solo
                      flat
                      hide-details
                      v-model="selectedVehicle.model"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="4"
                    class="text-right pr-2 black--text align-self-center"
                  >
                    Color
                  </v-col>
                  <v-col cols="8" class="text-left">
                    <v-text-field
                      solo
                      flat
                      hide-details
                      v-model="selectedVehicle.color"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row no-gutters>
                  <v-col
                    cols="4"
                    class="text-right pr-2 black--text align-self-center"
                  >
                    Validation Status
                  </v-col>
                  <v-col cols="8" class="text-left">
                    <v-select
                      solo
                      flat
                      hide-details
                      v-model="selectedVehicle.validationStatus"
                      :items="validationStatusOptions"
                    ></v-select>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="elevation-0" @click="resetDetails()">Reset</v-btn>
          <v-btn class="elevation-0" @click="editVehicleDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="secondary"
            class="elevation-0"
            @click="saveEditDetails()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="vehicleImgDialog" width="840px" eager>
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center position-relative">
          <v-row no-gutters class="justofy-space-between align-self-center">
            <v-col class="text-left align-self-center">
              <span>Vehicle Images</span>
            </v-col>
            <v-col class="pa-2 pr-4 text-end">
              <v-btn-toggle v-model="toggle_view" mandatory color="primary">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" value="carousel">
                      <v-icon>view_carousel</v-icon>
                    </v-btn>
                  </template>
                  <span>Carousel View</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" value="grid">
                      <v-icon>grid_view</v-icon>
                    </v-btn>
                  </template>
                  <span>Grid View</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="vehicle-img-dialog-close-btn"
                icon
                v-bind="attrs"
                v-on="on"
                @click="vehicleImgDialog = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-card-title>
        <v-divider class="py-1"></v-divider>
        <v-card-text class="text-center">
          <v-container fluid pa-0>
            <v-row
              no-gutters
              v-if="selectedVehicle.hasOwnProperty('duplicateImages')"
            >
              <v-col cols="12" v-show="toggle_view == 'carousel'">
                <v-carousel hide-delimiter-background show-arrows-on-hover>
                  <v-carousel-item
                    v-for="(image, i) in selectedVehicle.duplicateImages"
                    :key="'carouselimg-' + i"
                  >
                    <v-container fluid pa-0>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-btn
                            color="secondary"
                            @click="copyImageToClipboard(image)"
                            >Copy Image To Clipboard</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                    <div style="position: relative" :ref="image.imageName">
                      <v-img
                        crossOrigin="anonymous"
                        :src="
                          'https://divrt-lpr.s3.amazonaws.com/' +
                          image.imageName 
                        "
                        :lazy-src="
                          'https://divrt-lpr.s3.amazonaws.com/' +
                          image.imageName 
                        "
                        contain
                        height="100%"
                        class="grey lighten-2"
                      ></v-img>
                      <v-card
                        flat
                        tile
                        class="vehicle-carousel-info white black--text text-caption"
                        width="200"
                      >
                        <v-container fluid pa-0>
                          <v-row no-gutters class="ma-0">
                            <v-col cols="4" class="text-right pr-2"
                              >Plate</v-col
                            >
                            <v-col cols="8" class="text-left">{{
                              image.plate
                            }}</v-col>
                          </v-row>
                          <v-row no-gutters class="ma-0">
                            <v-col cols="4" class="text-right pr-2">Make</v-col>
                            <v-col cols="8" class="text-left">{{
                              image.make
                            }}</v-col>
                          </v-row>
                          <v-row no-gutters class="ma-0">
                            <v-col cols="4" class="text-right pr-2"
                              >Model</v-col
                            >
                            <v-col cols="8" class="text-left">{{
                              image.model
                            }}</v-col>
                          </v-row>
                          <v-row no-gutters class="ma-0">
                            <v-col cols="4" class="text-right pr-2"
                              >Color</v-col
                            >
                            <v-col cols="8" class="text-left">{{
                              image.color
                            }}</v-col>
                          </v-row>
                          <v-row no-gutters class="ma-0">
                            <v-col cols="4" class="text-right pr-2"
                              >State</v-col
                            >
                            <v-col cols="8" class="text-left">{{
                              selectedVehicle.state
                            }}</v-col>
                          </v-row>
                          <v-row no-gutters class="ma-0">
                            <v-col cols="4" class="text-right pr-2">Zone</v-col>
                            <v-col cols="8" class="text-left"
                              >{{ selectedVehicle.zid }} (Commerce B)</v-col
                            >
                          </v-row>
                          <v-row no-gutters class="ma-0">
                            <v-col cols="4" class="text-right pr-2">Date</v-col>
                            <v-col cols="8" class="text-left">{{
                              formatDate(image)
                            }}</v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col cols="12" v-show="toggle_view == 'grid'">
                <v-row no-gutters class="justify-space-around">
                  <v-col
                    v-for="(image, i) in selectedVehicle.duplicateImages"
                    :key="'gridimg-' + i"
                    cols="6"
                    class="pa-2"
                  >
                    <v-img
                      :src="
                        'https://divrt-lpr.s3.amazonaws.com/' + image.imageName
                      "
                      :lazy-src="
                        'https://divrt-lpr.s3.amazonaws.com/' + image.imageName
                      "
                      width="100%"
                      contain
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackBar" :color="snackBarColor">
      {{ snackBarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="showSnackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
// import { mapGetters } from "vuex";
import * as lprAPI from "@/api/lprAPI";
import { EventBus } from "@/lib/EventBus";
import S3 from "aws-sdk/clients/s3";
// import * as clipboard from "@/utils/copyToClipboard";
import { format } from "date-fns";
import { US_DATE_FORMAT, US_DATE_TIME_FORMAT } from "@/utils/constants";
export default {
  name: "LprListView",
  components: {},
  props: {
    lprData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    zoneFee: {
      type: Object,
      default: function () {
        return {};
      },
    },
    user: {
      type: Object,
      default: function () {
        return {};
      },
    },
    search: {
      type: String,
      default: function () {
        return "";
      },
    },
    lprTab: {
      type: String,
      default: function () {
        return "";
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    view: {
      type: String,
      default: function () {
        return "";
      },
    },
    filter: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      violationNotes: "",
      exceptionNotes: "",
      reviewValidation: "",
      selectedViolatedRule: "",
      violatedRules: [
        "Improperly Parked",
        "Overstay",
        "Invalid Plate",
        "Expired Receipt",
      ],
      parkingFee: "",
      violationFee: "",
      adminFee: "",
      showSnackBar: false,
      snackBarText: "",
      snackBarColor: "",
      confirmReportDialog: false,
      validationStatusOptions: [
        "Override: Violation",
        "Override: Exception",
        "Valid User",
      ],
      toggle_view: "carousel",
      vehicleImgDialog: false,
      selectedVehicle: {},
      totalPlates: 0,
      options: {},
      imgSources: [],
      lprHeaders: [
        {
          text: "Date",
          value: "camTimeEpoch",
          align: "center",
          sortable: true,
          width: "10%",
          class: "text-uppercase",
        },
        {
          text: "Vehicle Image",
          value: "thumbnail",
          align: "center",
          sortable: false,
          width: "6%",
          class: "imageClass text-uppercase",
        },
        {
          text: "Plate Image",
          value: "plate_image",
          sortable: false,
          align: "center",
          width: "6%",
          class: "text-uppercase",
        },
        {
          text: "Licence Plate",
          align: "center",
          sortable: true,
          value: "plate",
          width: "10%",
          cellClass: "position-relative",
          class: "text-uppercase",
        },
        {
          text: "Make",
          value: "make",
          align: "center",
          sortable: true,
          width: "10%",
          cellClass: "position-relative",
          class: "text-uppercase",
        },
        {
          text: "Model",
          value: "model",
          align: "center",
          sortable: true,
          width: "8%",
          cellClass: "position-relative",
          class: "text-uppercase",
        },
        {
          text: "Color",
          value: "color",
          align: "center",
          sortable: true,
          width: "6%",
          cellClass: "position-relative",
          class: "text-uppercase",
        },
        {
          text: "Lane",
          value: "laneType",
          align: "center",
          sortable: true,
          width: "6%",
          cellClass: "",
          class: "text-uppercase",
        },
        {
          text: "State",
          value: "state",
          align: "center",
          sortable: true,
          width: "6%",
          cellClass: "",
          class: "text-uppercase",
        },
        // { text: "Time", value: "timeOfDay", align: "left",sortable: false },
        {
          text: "Violation No",
          value: "voilationNumber",
          align: "center",
          sortable: false,
          width: "10%",
          // cellClass: "validation-status",
          class: "text-uppercase",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "10%",
          class: "text-uppercase",
        },
      ],
      editVehicleDialog: false,
      reportUserPlateBtnLoading: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.getLPRData();
      },
      deep: true,
    },
  },
  computed: {
    filterLprHeaders() {
      switch (this.lprTab) {
        case "issued":
          return this.lprHeaders;
        case "exception":
          return this.lprHeaders.filter((h) => !(h.value == "voilationNumber"));
        case "all":
          return this.lprHeaders.filter((h) => !(h.value == "voilationNumber"));
        case "pending":
          return this.lprHeaders.filter((h) => !(h.value == "voilationNumber"));
        default:
          return this.lprHeaders.filter((h) => !(h.value == "voilationNumber"));
      }
    },
  },
  mounted() {
    // this.getLPRData();
  },
  methods: {
    isEditable(item) {
      return (
        ((item.validationStatus == "Invalid User" ||
          item.validationStatus == "Valid User") &&
          this.lprTab == "all") ||
        this.lprTab == "pending"
      );
    },
    getFeeText(item) {
      return item.rateDescription + "  -  " + item.rate;
    },
    filtredData(data) {
      switch (this.filter) {
        case "all":
          return data;
        case "edited":
          return data.filter((record) => record.manuallyUpdated == true);
        case "editedbyme":
          return data.filter(
            (record) =>
              record.manuallyUpdated == true &&
              record.editedBy.name == this.user.name
          );
        case "reviewedbyme":
          return data.filter(
            (record) =>
              (record.validationStatus == "Override: Violation" ||
                record.validationStatus == "Override: Exception") &&
              record?.reviewedBy?.name &&
              record.reviewedBy.name == this.user.name
          );
        case "invalid":
          return data.filter(
            (record) =>
              record.validationStatus == "Invalid User" ||
              record.validationStatus == "Override: Violation" ||
              record.validationStatus == "Override: Exception"
          );
        case "flagged":
          return data.filter((record) => record.issuedTicket);
      }
      return data;
    },
    getImagePlate(item) {
      let box = item.box;
      let imageHeight = Number(box.ymax) - Number(box.ymin);
      let imageWidth = Number(box.xmax) - Number(box.xmin);
      let imageURL = "url('" + item.thumbnail + "')";
      let imagePosition =
        "-" + Number(box.xmin) + "px -" + Number(box.ymin) + "px";
      let zoom = imageWidth >= 120 ? "" : "zoom:" + 160 / imageWidth;
      let style =
        "height:" +
        imageHeight +
        "px;width:" +
        imageWidth +
        "px;background-position:" +
        imagePosition +
        "; background-image:" +
        imageURL +
        ";" +
        zoom;

      return style;
    },
    itemRowBackground(item) {
      if (
        (item.validationStatus == "Invalid User" ||
          item.validationStatus == "Override: Violation" ||
          item.validationStatus == "Override: Exception") &&
        !item.issuedTicket
      ) {
        return "row-invalid-user";
      } else if (item.issuedTicket) {
        return "row-ticket-issued";
      } else {
        return "";
      }
    },
    itemBackground(item) {
      return item.validationStatus == "Invalid User" ||
        item.validationStatus == "Override: Violation" ||
        item.validationStatus == "Override: Exception"
        ? "invalid-user"
        : "";
    },
    getParkingFeeText(key) {
      let obj = this.zoneFee.parkingFees.find((fee) => fee.feeDetailKey == key);
      return obj.rateDescription + " - " + obj.rate;
    },
    getViolationFeeText(key) {
      let obj = this.zoneFee.violationFees.find(
        (fee) => fee.feeDetailKey == key
      );
      return obj.rateDescription + " - " + obj.rate;
    },
    getAdminFeeText(key) {
      let obj = this.zoneFee.adminFees.find((fee) => fee.feeDetailKey == key);
      return obj.rateDescription + " - " + obj.rate;
    },
    onViolatedRuleChange() {
      // ["Improperly Parked", "Overstay", "Invalid Plate", "Expired Receipt" ]
      switch (this.selectedViolatedRule) {
        case "Improperly Parked":
          this.parkingFee = this.getParkingFeeText(142190);
          this.violationFee = this.getViolationFeeText(143034);
          this.adminFee = this.getAdminFeeText(143843);
          break;
        case "Overstay":
          this.parkingFee = this.getParkingFeeText(141928);
          this.violationFee = this.getViolationFeeText(143024);
          this.adminFee = this.getAdminFeeText(143843);
          break;
        case "Invalid Plate":
          this.parkingFee = this.getParkingFeeText(142190);
          this.violationFee = this.getViolationFeeText(143033);
          this.adminFee = this.getAdminFeeText(547798);
          break;
        case "Expired Receipt":
          this.parkingFee = this.getParkingFeeText(141928);
          this.violationFee = this.getViolationFeeText(143024);
          this.adminFee = this.getAdminFeeText(547798);
          break;
      }
    },
    openConfirmReportDialog(item) {
      this.selectedVehicle = Object.assign({}, this.selectedVehicle, item);
      this.confirmReportDialog = true;
      this.reviewValidation = "violation";
      this.selectedViolatedRule = "";
      this.exceptionNotes = "";
      this.violationNotes = "";
      this.parkingFee = "";
      this.violationFee = "";
      this.adminFee = "";
    },
    async markForException() {
      this.reportUserPlateBtnLoading = true;
      let data = [
        {
          sendForVoilation: false,
          voilation: null,
          exception: {
            validationStatus: "Override: Exception",
            notes: this.exceptionNotes,
          },
          reviewedBy: {
            email: this.user.email,
            name: this.user.name,
          },
        },
      ];
      let reportResult = await lprAPI.issueTicket(
        "62276",
        this.selectedVehicle._id,
        data
      );
      this.snackBarText =
        reportResult?.data?.status && reportResult.data.status == "success"
          ? "Exception added successfully"
          : "Error in issuing the ticket";
      this.snackBarColor =
        reportResult?.data?.status && reportResult.data.status == "success"
          ? "green"
          : "red";
      reportResult?.data?.status && reportResult.data.status == "success"
        ? EventBus.$emit("ticket-issued")
        : "";
      this.reportUserPlateBtnLoading = false;
      this.showSnackBar = true;
      this.confirmReportDialog = false;
    },
    async reportUserPlate() {
      this.reportUserPlateBtnLoading = true;
      let data = [
        {
          sendForVoilation: true,
          voilation: {
            issueTicket: {
              violationDate: "2021-02-17T10:35:50.789Z",
              violationNumber: "string",
              violationFee: 0,
              parkingFee: 0,
              licensePlate: "string",
              licensePlateState: "string",
              violationType: "string",
              locationCode: "string",
              adminFee: 0,
              voilationImage: {
                violationImage1: "string",
                violationImage2: "string",
                violationImage3: "string",
              },
            },
            validationStatus: "Override: Violation",
          },
          exception: {
            validationStatus: "Override: Exception",
            notes: "",
          },
          reviewedBy: {
            email: this.user.email,
            name: this.user.name,
          },
        },
      ];
      let reportResult = await lprAPI.issueTicket(
        "62276",
        this.selectedVehicle._id,
        data
      );
      this.snackBarText =
        reportResult?.data?.status && reportResult.data.status == "success"
          ? "Violation issued successfully"
          : "Error in issuing the ticket";
      this.snackBarColor =
        reportResult?.data?.status && reportResult.data.status == "success"
          ? "green"
          : "red";
      reportResult?.data?.status && reportResult.data.status == "success"
        ? EventBus.$emit("ticket-issued")
        : "";
      this.reportUserPlateBtnLoading = false;
      this.showSnackBar = true;
      this.confirmReportDialog = false;
    },
    // async getLPRData() {
    //   this.loading = true;
    // },
    openVehicleImgDialog(item) {
      (this.toggle_view = "carousel"),
        (this.selectedVehicle = Object.assign({}, this.selectedVehicle, item));
      this.vehicleImgDialog = true;
      // this.getImagesFromAWS(item);
    },
    openEditVehicleDialog(item) {
      this.editVehicleDialog = true;
      this.selectedVehicle = Object.assign({}, this.selectedVehicle, item);
    },
    getImagesFromAWS(item) {
      this.imgSources = [];
      var self = this;
      item.duplicateImages.forEach((e) => {
        console.log(e);
        let imgBucket = "divrt-lpr";
        //  e.imagePath
        //   .split("/")[2]
        //   .split(".")[0]
        //   .split("-thumb")[0];
        // console.log(self.getObject(imgBucket, e.vehicleDetails.img_imgName))
        self
          .getObject(imgBucket, e.imageName)
          .then((a) => self.imgSources.push(a));
        // self.imgSources.push(self.getObject(imgBucket, e.vehicleDetails.img_imgName))
      });
    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    convertImgToBase64URL(item, url, callback, outputFormat) {
      var self = this;
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        var canvas = document.createElement("CANVAS"),
          ctx = canvas.getContext("2d"),
          dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        let margin = 20;
        let leftMargin = 30;
        let nameMargin = 120;
        ctx.drawImage(img, 0, 0);
        //  ctx.fillStyle="#fff";
        ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
        ctx.fillRect(img.width - 250, 0, 250, 200);
        ctx.textAlign = "end";
        ctx.fillStyle = "#000";
        ctx.font = "12px Arial";
        ctx.fillText("Plate ", img.width - leftMargin - nameMargin, 50);
        ctx.fillText("Make ", img.width - leftMargin - nameMargin, 70);
        ctx.fillText("Model ", img.width - leftMargin - nameMargin, 90);
        ctx.fillText("Color ", img.width - leftMargin - nameMargin, 110);
        ctx.fillText("State ", img.width - leftMargin - nameMargin, 130);
        ctx.fillText("Zone ", img.width - leftMargin - nameMargin, 150);
        ctx.fillText("Date ", img.width - leftMargin - nameMargin, 170);
        ctx.textAlign = "start";
        ctx.fillText(item.plate, img.width - margin - nameMargin, 50);
        ctx.fillText(item.make, img.width - margin - nameMargin, 70);
        ctx.fillText(item.model, img.width - margin - nameMargin, 90);
        ctx.fillText(item.color, img.width - margin - nameMargin, 110);
        ctx.fillText(item.state, img.width - margin - nameMargin, 130);
        ctx.fillText(
          item.zid + "( Commerce B )",
          img.width - margin - nameMargin,
          150
        );
        ctx.fillText(
          self.formatDate(item),
          img.width - margin - nameMargin,
          170
        );
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null;
      };
      img.src = url;
    },
    async copyImageToClipboard(item) {
      var self = this;
      this.convertImgToBase64URL(
        item,
        "https://divrt-lpr.s3.amazonaws.com/" +
          item.imageName +
          "?" +
          new Date().getTime(),
        async function (base64Img) {
          let blob = await self.dataURItoBlob(base64Img);
          const data = [new window.ClipboardItem({ [blob.type]: blob })];
          await navigator.clipboard.write(data);
        }
      );
      // setTimeout(async()=>{}, 3000)
      // const el = this.$refs[img];
      // // add option type to get the image version
      // // if not provided the promise will return
      // // the canvas.
      // // const options = {
      // //   type: 'dataURL'
      // // }
      // el[0].children[0].children[1].style.backgroundImage = 'url("https://divrt-lpr.s3.amazonaws.com/SPCOMB02_2020-12-24_10-25-31-88.jpg?'+new Date().getTime()+'")';

      // let output = await this.$html2canvas(this.$refs[img][0], {
      //   type: "dataURL",
      //   useCORS: true,
      //   allowTaint : false,
      //   logging: false,
      // });
      // console.log(output)
      // let blob = await this.dataURItoBlob(output); //.blob();
      // const data = [new window.ClipboardItem({ [blob.type]: blob })];
      // //   setTimeout(async()=>console.log(
      // // await clipboard.setToClipboard(blob)), 3000)

      // // this.$refs[img][0].$el.focus();
      // //  this.$refs[img][1].$el.focus();
      // await navigator.clipboard.write(data).then(
      //   function () {
      //     console.log("Image copied successfully");
      //     /* success */
      //   },
      //   function (e) {
      //     /* failure */
      //     console.log(e);
      //     console.log("copying image failed");
      //   }
      // );
      // await navigator.clipboard.write(data)
      // await clipboard.setToClipboard(output);
      // let result = clipboard.CopyImageById(imgSrc);
      // console.log(result)
      // let permission = await clipboard.askWritePermission();
      // console.log(permission);
      // if (permission) {
      //   let isCopied = await clipboard.copyImageToClipboard(imgSrc);
      //   console.log(isCopied);
      // }
    },
    async saveFieldEditDetails(item) {
      this.editVehicleDialog = false;
      let editedData = {
        plate: item.plate,
        make: item.make,
        model: item.model,
        color: item.color,
        email: this.user.email,
        name: this.user.name,
        resetValues: false,
      };
      let result = await lprAPI.updateLPRData(item._id, editedData);
      this.snackBarText =
        result?.data?.status && result.data.status == "success"
          ? "Successfully updated the vehicle details"
          : "Error in updating the vehicle detais";
      this.snackBarColor =
        result?.data?.status && result.data.status == "success"
          ? "green"
          : "red";
      result?.data?.status && result.data.status == "success"
        ? EventBus.$emit("user-plate-edited")
        : "";
      this.showSnackBar = true;
    },
    async resetDetails() {
      this.editVehicleDialog = false;
      let editedData = {
        plate: "",
        make: "",
        model: "",
        color: "",
        email: this.user.email,
        name: this.user.name,
        resetValues: true,
      };
      let result = await lprAPI.updateLPRData(
        this.selectedVehicle._id,
        editedData
      );
      this.snackBarText =
        result?.data?.status && result.data.status == "success"
          ? "vehicle details has been reset successfully"
          : "Error in resetting the vehicle detais";
      this.snackBarColor =
        result?.data?.status && result.data.status == "success"
          ? "green"
          : "red";
      result?.data?.status && result.data.status == "success"
        ? EventBus.$emit("user-plate-edited")
        : "";
      this.showSnackBar = true;
    },
    async saveEditDetails() {
      this.editVehicleDialog = false;
      let editedData = {
        plate: this.selectedVehicle.plate,
        make: this.selectedVehicle.make,
        model: this.selectedVehicle.model,
        color: this.selectedVehicle.color,
        email: this.user.email,
        name: this.user.name,
        resetValues: false,
      };
      let result = await lprAPI.updateLPRData(
        this.selectedVehicle._id,
        editedData
      );
      this.snackBarText =
        result?.data?.status && result.data.status == "success"
          ? "Successfully updated the vehicle details"
          : "Error in updating the vehicle detais";
      this.snackBarColor =
        result?.data?.status && result.data.status == "success"
          ? "green"
          : "red";
      result?.data?.status && result.data.status == "success"
        ? EventBus.$emit("user-plate-edited")
        : "";
      this.showSnackBar = true;
    },
    encodeImageData(data) {
      var str = data.reduce(function (a, b) {
        return a + String.fromCharCode(b);
      }, "");
      return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
    },
    formatDate(item) {
      return (
        format(new Date(Number(item.camTimeEpoch)), US_DATE_FORMAT) +
        " " +
        item.camTimeText
      );
    },
    formatISODate(date) {
      return format(new Date(date), US_DATE_TIME_FORMAT);
    },
    async getObject(bucket, objectKey) {
      var s3 = new S3({
        region: "us-east-1",
        accessKeyId: "AKIA5CMEGDSMGFCDHMPU",
        secretAccessKey: "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",
      });
      try {
        const params = {
          Bucket: bucket,
          Key: objectKey,
        };

        const data = await s3.getObject(params).promise();

        return "data:image/png;base64," + this.encodeImageData(data.Body); //.toString("utf-8");
      } catch (e) {
        throw new Error(`Could not retrieve file from S3: ${e.message}`);
      }
    },
  },
};
</script>
<style lang="sass">
.cursor-pointer
  cursor: pointer

.image-count
  position: absolute
  top: 10px
  right: 10px
  font-size: 10px

.v-card--reveal
  align-items: center
  bottom: 0
  left: 0
  justify-content: center
  opacity: 0.9
  position: absolute
  width: 100%

.cursor-pointer
  cursor: pointer

.manual-update
  position: absolute
  top: 40px
  right: 10px

.vehicle-carousel-info
  position: absolute !important
  top: 0px
  right: 0px
  opacity: 0.8

.row-invalid-user td.validation-status
  background: #FFCDD2 !important

.invalid-user
  background: #FFCDD2 !important

.row-ticket-issued td.validation-status
  background: #FFCCBC !important

.text-uppercase
  text-transform: upppercase

.card-info
  font-size: 12px

.plate-warning-icon
  position: absolute !important
  bottom: 5%
  right: 5%

.plate-edit-check-icon
  position: absolute !important
  bottom: 5%
  right: 20%

.edit-check-icon
  position: absolute !important
  bottom: 5%
  right: 5%

.position-relative
  position: relative

.hide
  display: none

.report-vehicle-actions
  position: absolute
  bottom: 0
  width: 100%

.vehicle-img-dialog-close-btn
  position: absolute
  top: 0
  right: 0
</style>
